// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/dashboard/ico-tit.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/dashboard/tit-line.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".group-title[data-v-44a8ae42] {\n  color: #00fff1;\n  height: 30px;\n  line-height: 30px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat left center;\n  background-size: 10px;\n  padding-left: 20px;\n  background-color: rgba(13, 21, 21, 0.7);\n  -webkit-backdrop-filter: saturate(180%) blur(15px);\n  -moz-backdrop-filter: saturate(180%) blur(15px);\n  backdrop-filter: saturate(180%) blur(15px);\n}\n.group-title span[data-v-44a8ae42] {\n  font-size: 14px;\n  text-transform: uppercase;\n  letter-spacing: 2px;\n}\n.group-title span[data-v-44a8ae42]:last-child {\n  font-size: 14px;\n}\n.group-title-animate[data-v-44a8ae42] {\n  position: relative;\n  width: 350px;\n  overflow: hidden;\n  height: 1px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat left bottom;\n  background-size: 350px;\n}\n.group-title-animate span[data-v-44a8ae42] {\n  position: absolute;\n  display: block;\n}\n.group-title-animate span[data-v-44a8ae42]:nth-child(1) {\n  top: 0;\n  left: -20%;\n  width: 25%;\n  height: 1px;\n  background: -webkit-gradient(linear, left top, right top, from(transparent), to(#03e9f4));\n  background: linear-gradient(90deg, transparent, #03e9f4);\n  -webkit-animation: title-animate-data-v-44a8ae42 4s linear infinite;\n          animation: title-animate-data-v-44a8ae42 4s linear infinite;\n}\n@-webkit-keyframes title-animate-data-v-44a8ae42 {\n0% {\n    left: -50%;\n}\n50%,\n  100% {\n    left: 100%;\n}\n}\n@keyframes title-animate-data-v-44a8ae42 {\n0% {\n    left: -50%;\n}\n50%,\n  100% {\n    left: 100%;\n}\n}\n", ""]);
// Exports
module.exports = exports;
