<template>
  <div>
    <div class="group-title">
      <span>{{ title }}</span>
      <span>{{ secondTitle }}</span>
    </div>
    <div class="group-title-animate"><span></span></div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: () => {}
    },
    secondTitle: {
      type: String,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.group-title {
  color: #00fff1;
  height: 30px;
  line-height: 30px;
  background: url('../../../../assets/images/dashboard/ico-tit.png') no-repeat left center;
  background-size: 10px;
  padding-left: 20px;
  background-color: rgba(13, 21, 21, 0.7);
  -webkit-backdrop-filter: saturate(180%) blur(15px);
  -moz-backdrop-filter: saturate(180%) blur(15px);
  backdrop-filter: saturate(180%) blur(15px);
  span {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  span:last-child {
    font-size: 14px;
  }
}

.group-title-animate {
  position: relative;
  width: 350px;
  overflow: hidden;
  height: 1px;
  background: url('../../../../assets/images/dashboard/tit-line.png') no-repeat left bottom;
  background-size: 350px;
}
.group-title-animate span {
  position: absolute;
  display: block;
}
.group-title-animate span:nth-child(1) {
  top: 0;
  left: -20%;
  width: 25%;
  height: 1px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: title-animate 4s linear infinite;
}
@keyframes title-animate {
  0% {
    left: -50%;
  }
  50%,
  100% {
    left: 100%;
  }
}
</style>
